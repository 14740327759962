<template>
  <section >
    <h2 class="titulo-links-escolha">EDITAR DISCIPLINA PARA O PROFESSOR {{nomeProfessor}}</h2>
    <div class="form-elements">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="$t('forms.inputs.title')">
            <va-card-content>
              <form>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="sel1">Selecione o segmento e a disciplina a serem editados:</label>
                    <select class="form-control" v-model="servidoreEspecializacaoSelecionada" @change="chamarFuncoes(servidoreEspecializacaoSelecionada)">
                        <option v-for="n in servidorEspecializacao" :key="n" :value="n.id" >{{n.servidorSegmentoDisciplina}}</option>
                    </select>
                  </div>
                  <div class="col-md-12"></div>
                  <div class="form-group col-md-6" v-if="professoresALL.length">
                    <label for="sel1">Selecione o novo professor</label>
                    <select class="form-control" v-model="professorSelecionado">
                        <option v-for="n in professoresALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-12"></div>
                  <div class="form-group col-md-6" v-if="segmentoALL.length">
                    <label for="sel1">Selecione o novo segmento:</label>
                    <select class="form-control" v-model="segmentoSelecionado" >
                        <option v-for="n in segmentoALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-12"></div>
                  <div class="form-group col-md-6" v-if="disciplinaALL.length">
                    <label for="sel1">Selecione a nova disciplina:</label>
                    <select class="form-control" v-model="disciplinaSelecionada">
                        <option v-for="n in disciplinaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>
                  <div class="flex">
                    <va-button class="mr-2 mb-2"  @click="alterar"> {{ $t('Alterar') }}</va-button>
                  </div>
                </div>
              </form>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>
  </section >
</template>

<script>

import { Professor } from "@/class/professor.js";
import { Servidores } from "@/class/servidores.js";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";


import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  props: {
   id:{
      required: true
    },
    nomeProfessor:{
      required: true
    },
  },
  components: {

  },
  name: 'Servidores-Escola',
  data () {
    return {
      servidorEspecializacao:[],
      servidoreEspecializacaoSelecionada:'',
      segmentoALL:[],
      disciplinaALL:[],
      professoresALL:[],
      segmentoSelecionado:'',
      disciplinaSelecionada:'',
      professorSelecionado:'',
    }
  },
  methods: {
    chamarFuncoes(servidoreEspecializacaoSelecionada) {
      this.listarTudoSegmentos();
      this.listarTudoDisciplina();
      this.listarTudoProfessor();
    },
    async alterar() {
      let novo = {
        id:this.servidoreEspecializacaoSelecionada,
        servidor_id: this.professorSelecionado,
        segmento_id: this.segmentoSelecionado,
        disciplina_id: this.disciplinaSelecionada
      }
      try {
        const data = await Servidores.alterarEspecializacao(novo);
        this.$vaToast.init({
          message: "Alterado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
      catch(e) {
        this.$vaToast.init({
          message: "Erro ao alterar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
    },
    async listarTudoProfessor() {
      try {
        let data = await Servidores.obtemTodos();
        for (const el of data.data) {
          if (el.login.funcao == 5) {
            let novo = {
              id:el.id,
              nome:el.nome,
            }
            this.professoresALL.push(novo);
          }
        }
      } catch (e) {}
    },
    async listarTudoSegmentos() {
      try {
        let data = await SegmentoEscolar.obtemTodos();
        this.segmentoALL = data.data;
      } catch (e) {}
    },
    async listarTudoDisciplina() {
      try {
        let data = await DisciplinaEscolar.obtemTodos();
        this.disciplinaALL = data.data;
      } catch (e) {}
    },
    async listarServidoresEspecializacao() {
      try {
        let data = await Servidores.visualizarEspecializaoProf(this.id);
        for (const el of data.data) {
          let novo = {
            id:el.id,
            servidorNome:null,
            servidor_id:el.servidor_id,
            segmentoNome:null,
            segmento_id:el.segmento_id,
            disciplinaNome:null,
            disciplina_id:el.disciplina_id,
            servidorSegmentoDisciplina:null
          }
          let data1 = await Servidores.obtemUm(el.servidor_id);
          //novo.servidorNome = data1.data[0].nome;
          novo.servidorSegmentoDisciplina = data1.data[0].nome + ' - ';
          let data2 = await SegmentoEscolar.obtemUm(el.segmento_id);
          novo.segmentoNome = data2.data.nome;
          novo.servidorSegmentoDisciplina += data2.data.nome + ' - ';
          let data3 = await DisciplinaEscolar.obtemUm(el.disciplina_id);
          novo.disciplinaNome = data3.data.nome;
          novo.servidorSegmentoDisciplina += data3.data.nome;
          this.servidorEspecializacao.push(novo);
        }

      } catch (e) {}
    },
    async listarTudoSegmentos() {
      try {
        let data = await SegmentoEscolar.obtemTodos();
        this.segmentoALL = data.data;

      } catch (e) {}
    },
    async listarTudoDisciplina() {
      try {
        let data = await DisciplinaEscolar.obtemTodos();
        this.disciplinaALL = data.data;
      } catch (e) {}
    },

    async editar() {
      try {
        this.professor.servidor_id = this.id;
        this.professor.segmento_id = this.segmentoSelecionado.id;
        this.professor.disciplina_id = this.disciplinaSelecionada.id;
        const data = await Servidores.addEspecProfessor(this.professor);
        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.$router.push({name: 'servidores'});
      }
      catch(e) {
        alert(e);
        this.$vaToast.init({
          message: "Erro ao cadastrar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
    },
  },
  mounted() {
    if (this.id == null) {
      this.$router.push({name: 'servidores'});
    }
    else {
        this.listarServidoresEspecializacao();
    }
  }
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
